import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PageTitle from "./../components/common/PageTitle";
import FileUpload from "./../components/common/FileUpload";
import SmallStats from "./../components/common/SmallStats";
import UsersOverview from "./../components/blog/UsersOverview";
import UsersTable from "../components/blog/UsersTable";
import SchoolUsersTable from "../components/blog/SchoolUsersTable";
import LogoutBtn from "../components/common/Logout";
import { API_HOST } from "../config";

const Overview = () => {
  const navigate = useNavigate();
  const [member_count, set_member_count] = useState(1);
  const [file_sum, set_file_sum] = useState(1);
  const [school_count, set_school_count] = useState(1);
  const [validMember, set_validMember] = useState(1);
  const [update, setUpdate] = useState(0);
  const apiUrl = API_HOST + "/upload";

  useEffect(() => {
    const userState = localStorage.getItem("userState");
    if (parseInt(userState) !== 1) {
      navigate("/login");
      return;
    }

    var timePickerValue = document.getElementById("timePicker").value;
    if (timePickerValue === "") {
      timePickerValue = new Date();
    }
    var json = JSON.stringify({
      timestamp: timePickerValue,
    });
    fetch(API_HOST + "/count", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: json,
    })
      .then((res) => res.json())
      .then((data) => {
        handleStats(
          data.member_count,
          data.file_sum,
          data.school_count,
          data.validMember
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, [update]);

  const handleStats = (member_count, file_sum, school_count, validMember) => {
    set_member_count(member_count);
    set_file_sum(file_sum);
    set_school_count(school_count);
    set_validMember(validMember);
  };

  const handleUpdate = () => {
    setUpdate(update + 1);
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row className="page-header py-4">
        <PageTitle
          title="Overview"
          subtitle="Dashboard"
          className="text-sm-left mb-3"
        />
        <input
          type="date"
          className="timePicker form-control"
          id="timePicker"
          onChange={handleUpdate}
        />
        <LogoutBtn />
      </Row>

      <Row>
        <Col lg="6" md="12" sm="12">
          {/* Upload File */}
          <Col lg="12" md="12" sm="12" className="mb-4">
            <FileUpload apiUrl={apiUrl} handleUpdate={handleUpdate} />
          </Col>
          {/* Small Stats Blocks */}
          <Row>
            <Col md="6" xs="6" className="mb-4">
              <SmallStats
                variation="1"
                label={"會員總人數"}
                value={member_count}
              />
            </Col>
            <Col md="6" xs="6" className="mb-4">
              <SmallStats variation="1" label={"檔案總數"} value={file_sum} />
            </Col>
            <Col md="6" xs="6" className="mb-4">
              <SmallStats
                variation="1"
                label={"有效會員數"}
                value={validMember}
              />
            </Col>
            <Col md="6" xs="6" className="mb-4">
              <SmallStats
                variation="1"
                label={"學校認證人數"}
                value={school_count}
              />
            </Col>
          </Row>
        </Col>

        {/* Users Overview */}
        <Col lg="6" md="12" sm="12" className="mb-4">
          <UsersOverview update={update} />
        </Col>

        {/* School Users Table */}
        <Col lg="6" md="12" sm="12" className="mb-4">
          <SchoolUsersTable update={update} />
        </Col>

        {/* Users Table */}
        <Col lg="6" md="12" sm="12" className="mb-4">
          <UsersTable update={update} />
        </Col>
      </Row>
    </Container>
  );
};

export default Overview;
