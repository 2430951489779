import React, { useState, useEffect, useRef } from "react";
import SelectImg from "../../images/select.svg";

const SelectableList = ({ years, onSelectedYearsChange }) => {
  const [selectedYears, setSelectedYears] = useState([]);
  const listItemsRef = useRef(null);

  useEffect(() => {
    const handleSelectBtnClick = (event) => {
      const selectBtn = document.querySelector(".select-btn");
      const listItems = listItemsRef.current;
      const isInsideListItems = listItems && listItems.contains(event.target);
      if (isInsideListItems && selectBtn.classList.contains("open")) {
        return;
      } else if (selectBtn.contains(event.target)) {
        selectBtn.classList.toggle("open");
      } else {
        selectBtn.classList.remove("open");
      }
    };
    document.addEventListener("click", handleSelectBtnClick);
    return () => {
      document.removeEventListener("click", handleSelectBtnClick);
    };
  }, []);

  const handleItemClick = (event) => {
    const item = event.target.closest(".item");
    if (item) {
      item.classList.toggle("checked");
      const checked = document.querySelectorAll(".checked");
      const yearArray = Array.from(checked).map((item) => item.textContent);
      setSelectedYears(yearArray);
      onSelectedYearsChange(yearArray);
    }
  };

  return (
    <div>
      <div className="select-btn">
        {selectedYears.length > 0 ? (
          <div className="select-text">
            選擇年份：{selectedYears.join(", ")}
          </div>
        ) : (
          <div className="select-text">請選擇年份</div>
        )}
        <img className="select-img" src={SelectImg} alt="" />
      </div>
      <ul ref={listItemsRef} className="list-items" onClick={handleItemClick}>
        {years.map((year) => (
          <li key={year} className="item">
            {year}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectableList;
