import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody } from "../card";

const FileUpload = ({ apiUrl, handleUpdate }) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [showStatus, setShowStatus] = useState(uploadStatus);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = (event) => {
    setUploadStatus(1);
    setShowStatus(`loading...`);
    event.preventDefault();
    event.persist();

    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        handleUpdate();
        setShowStatus(`Upload succeeded!`);
        setTimeout(() => {
          setUploadStatus(null);
          setTimeout(() => {
            if (event.target) {
              event.target.reset();
              setFile(null);
            }
          }, 500);
        }, 1000);
      })
      .catch((error) => {
        setShowStatus(`Upload failed ${error}`);
        setTimeout(() => {
          setUploadStatus(null);
        }, 1000);
      });
  };

  return (
    <Card>
      <CardHeader className="d-flex border-bottom justify-content-between">
        <h6 className="m-0">上傳檔案</h6>
      </CardHeader>
      <CardBody>
        <form className="file-form" onSubmit={handleUpload}>
          <input
            type="file"
            className="form-control"
            onChange={handleFileChange}
          />
          <button className="form-control" type="submit">
            Upload
          </button>
        </form>
        <div className={`upload-modal ${uploadStatus ? "show" : ""}`}>
          <div className="upload-text">
            {file ? showStatus : "WARNING：未上傳檔案"}
          </div>
          <div className={`progress-bar ${file ? "" : "warn"}`}></div>
        </div>
      </CardBody>
    </Card>
  );
};

FileUpload.propTypes = {
  apiUrl: PropTypes.string.isRequired,
};

export default FileUpload;
