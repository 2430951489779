import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card, CardHeader, CardBody } from "../card";
import SelectableList from "../common/SelectableList";
import Table from "../common/Table";
import { API_HOST } from "../../config";

const UsersTable = ({ update }) => {
  const [data, setData] = useState(null);
  const [sort, setSort] = useState({
    member_sort: "",
    output_sort: "",
    file_sort: "",
    second_sort: "",
  });
  const [SelectedYears, setSelectedYears] = useState([]);
  const years = ["108", "109", "110", "111", "112"];

  useEffect(() => {
    var timePickerValue = document.getElementById("timePicker").value;
    if (timePickerValue === "") {
      timePickerValue = new Date();
    }
    let requestBody = {
      member_sort: sort.member_sort,
      output_sort: sort.output_sort,
      file_sort: sort.file_sort,
      second_sort: sort.second_sort,
      year: SelectedYears,
      timestamp: timePickerValue,
    };
    fetch(API_HOST + "/valid_member", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [sort, SelectedYears, update]);

  const handleSelectedYearsChange = (years) => {
    setSelectedYears(years);
  };

  const handleSort = (newSort) => {
    setSort(newSort);
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const columns = [
    {
      label: "縣市",
      accessor: "city",
      sort: undefined,
    },
    {
      label: "學校",
      accessor: "school",
      sort: undefined,
    },
    {
      label: "會員數",
      accessor: "member_count",
      sort: "member_sort",
    },
    {
      label: "產出檔案會員數",
      accessor: "output_count",
      sort: "output_sort",
    },
    {
      label: "產出檔案率",
      accessor: "output_rate",
      sort: undefined,
    },
    {
      label: "檔案數",
      accessor: "output_sum",
      sort: "file_sort",
    },
    {
      label: "二次登入會員數",
      accessor: "second_count",
      sort: "second_sort",
    },
  ];

  return (
    <Row>
      <Col lg="12">
        <Card small>
          <CardHeader className="d-flex border-bottom justify-content-between">
            <h6 className="m-0">有效會員分析</h6>
            <SelectableList
              years={years}
              onSelectedYearsChange={handleSelectedYearsChange}
            />
          </CardHeader>
          <CardBody className="table-card">
            <Table columns={columns} data={data.table} onSort={handleSort} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UsersTable;
