import React from "react";
import { Navigate } from "react-router-dom";
import Overview from "./views/Overview";
import Login from "./views/Login";
import Errors from "./views/Errors";

export default [
  {
    path: "/",
    exact: true,
    element: <Navigate to="/overview" />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/overview",
    element: <Overview />,
  },
  {
    path: "/errors",
    element: <Errors />,
  },
];
