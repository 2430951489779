import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const userState = localStorage.getItem("userState");
    if (parseInt(userState) === 1) {
      navigate("/overview");
    }
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;
    if (username === "gWdKmPM7V5vtbhRa" && password === "WpXMIcPGYrqg4FLW") {
      navigate("/overview");
      localStorage.setItem("userState", 1);
    } else {
      alert("帳密錯誤");
    }
  };
  return (
    <div className="card login-wrap ">
      <h3>管理員登入</h3>
      <form className="login-form" onSubmit={handleLogin}>
        <div className="form-item">
          <label>Username</label>
          <input
            name="username"
            type="text"
            placeholder="Enter Username"
            required
          />
        </div>
        <div className="form-item">
          <label>Password</label>
          <input
            name="password"
            type="password"
            placeholder="Enter Password"
            required
          />
        </div>
        <button type="submit" className="btn btn-white">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
