import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Table = ({ columns, data, onSort }) => {
  const [sort, setSort] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [displayPages, setDisplayPages] = useState([]);
  const [itemsPerPageOptions, setItemsPerPage] = useState(10);

  // const rowsPerPage = 10;
  const totalRows = data.length;
  const totalPages = Math.ceil(totalRows / itemsPerPageOptions);

  const getNextSort = (currentSort) => {
    switch (currentSort) {
      case "desc":
        return "asc";
      case "asc":
        return "";
      default:
        return "desc";
    }
  };

  const handleSort = (col) => {
    const newSort = { ...sort };
    newSort[col] = getNextSort(sort[col]);
    setSort(newSort);

    // 回傳 res_data 給父層元素
    const res_data = {};
    columns.forEach((col) => {
      if (col.sort !== undefined) {
        res_data[col.sort] = newSort[col.accessor] || "";
      }
    });
    onSort(res_data);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleClickPage = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const startIdx = (currentPage - 1) * itemsPerPageOptions;
  const endIdx = startIdx + itemsPerPageOptions;
  const slicedData = data.slice(startIdx, endIdx);

  const updateDisplayPages = (currentPage, totalPages) => {
    if (totalPages <= 5) {
      setDisplayPages(Array.from({ length: totalPages }, (_, i) => i + 1));
      return;
    }

    const leftEdge = 1;
    const rightEdge = totalPages;
    const leftGap = currentPage - leftEdge;
    const rightGap = rightEdge - currentPage;
    let display = [];

    if (leftGap >= 3 && rightGap >= 3) {
      display = [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages,
      ];
    } else if (leftGap >= 3 && rightGap <= 3) {
      display = [1, "..."];
      for (let i = rightEdge - 3; i <= rightEdge; i++) {
        display.push(i);
      }
    } else if (leftGap <= 3 && rightGap >= 3) {
      for (let i = leftEdge; i <= leftEdge + 3; i++) {
        display.push(i);
      }
      display.push("...", totalPages);
    } else {
      for (let i = 1; i <= 5; i++) {
        display.push(i);
      }
      display[4] = "...";
    }

    setDisplayPages(display);
  };

  useEffect(() => {
    updateDisplayPages(currentPage, totalPages);
  }, [currentPage, totalPages]);

  return (
    <>
      <div className="table-wrap">
        <table className="table">
          <thead className="thead">
            <tr>
              {columns.map((col) =>
                col.sort !== undefined ? (
                  <th
                    key={col.accessor}
                    className={`th sort ${sort[col.accessor] ? "sorted" : ""}`}
                    onClick={() => handleSort(col.accessor)}
                  >
                    <div className="d-flex">
                      <div>{col.label}</div>
                      <button className="table-btn" theme="white">
                        {sort[col.accessor] === "asc"
                          ? "↿"
                          : sort[col.accessor] === "desc"
                          ? "⇂"
                          : "↿⇂"}
                      </button>
                    </div>
                  </th>
                ) : (
                  <th key={col.accessor} className="th">
                    <div className="d-flex">
                      <div>{col.label}</div>
                    </div>
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody className="tbody">
            {slicedData.map((row, i) => (
              <tr key={i}>
                {columns.map((col, j) => (
                  <td key={j} className="td">
                    {row[col.accessor]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <span className="pages">{`第 ${currentPage} 頁，共 ${totalPages} 頁`}</span>
        <button
          className="pagination-btn change"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {displayPages.map((pageNum, index) => {
          return pageNum === "..." ? (
            <span key={index}>...</span>
          ) : (
            <button
              className={`pagination-btn ${
                currentPage === pageNum ? "current" : ""
              }`}
              key={index}
              onClick={() => handleClickPage(pageNum)}
              disabled={currentPage === pageNum}
            >
              {pageNum}
            </button>
          );
        })}
        <button
          className="pagination-btn change"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
        <select
          className="select"
          value={itemsPerPageOptions}
          onChange={handleItemsPerPageChange}
        >
          <option value={10}>每頁10列</option>
          <option value={15}>每頁15列</option>
          <option value={20}>每頁20列</option>
        </select>
      </div>
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      sort: PropTypes.string,
    }).isRequired
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSort: PropTypes.func.isRequired,
};

export default Table;
