import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// 導入 routes.js 檔案中定義的路由
import routes from "./routes";
// 導入 Bootstrap 樣式表
import "bootstrap/dist/css/bootstrap.min.css";
// 導入 Shards Dashboard React 的樣式表
import "./style/shards-dashboards.1.1.0.min.css";

// 渲染 Shards Dashboard React 的路由和頁面組件
const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={route.element}
              />
            );
          })}
        </Routes>
      </div>
    </Router>
  );
};
export default App;
