import React, { useState, useEffect, useRef } from "react";
import { Card, CardHeader, CardBody } from "../card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { API_HOST } from "../../config";
let bar_chart;

const UsersOverview = ({ update }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState("desc");
  const [maxPages, setMaxPages] = useState(1);

  const canvasRef = useRef();

  useEffect(() => {
    let requestBody = {
      page: currentPage,
      sort: sortDirection,
    };
    fetch(API_HOST + "/school_count", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        renderChart(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [currentPage, sortDirection, update]);

  function renderChart(data) {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const labels = data.school_list.map((school) => school.school);
    const counts = data.school_list.map((school) => school.count);
    setMaxPages(data.page_sum);

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "用戶數量",
          data: counts,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
      ],
    };

    if (typeof bar_chart !== "undefined") {
      bar_chart.destroy();
    }

    bar_chart = new Chart(ctx, {
      plugins: [ChartDataLabels],
      type: "bar",
      data: chartData,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 1800,
                stepSize: 150,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        plugins: {
          datalabels: {
            anchor: "end",
            align: "top",
            formatter: Math.round,
            font: {
              size: 16,
            },
          },
        },
      },
    });
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSortChange = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setCurrentPage(1);
  };

  const handleBarsPerPageChange = (e) => {
    setCurrentPage(parseInt(e.target.value));
  };

  return (
    <Row className="chart-wrap">
      <Col lg="12">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">各校會員數</h6>
          </CardHeader>
          <CardBody className="pt-0">
            <Row className="border-bottom py-2 bg-light">
              <Col sm="12" className="d-flex mb-sm-0 justify-content-end">
                <div className="btn-group">
                  <button className="btn btn-white" onClick={handleSortChange}>
                    {sortDirection === "asc" ? "↿" : "⇂"}
                  </button>
                  <button className="btn btn-white" onClick={handlePrevPage}>
                    Prev
                  </button>
                  <button className="btn btn-white" onClick={handleNextPage}>
                    Next
                  </button>
                </div>

                <select
                  size="sm"
                  className="select"
                  value={currentPage}
                  onChange={handleBarsPerPageChange}
                >
                  {Array.from({ length: maxPages }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      Page {i + 1}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <canvas id="chart" ref={canvasRef} className="mt-3 chart" />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UsersOverview;
